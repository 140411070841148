<template>
  <div>
    <div class="carousel-form">
      <!-- <el-input
        v-model="input"
        placeholder="按用户名/用户类别查找"
        @input="flite(input)"
        style="width: 300px;margin-right:20px"
      ></el-input> -->
      <!-- <el-button type="primary"  @click="addCarousel">新增</el-button> -->
    </div>
    <div class="carousel-table">
      <div class="title_file">文件列表</div>
      <el-table :data="tableData" ref="tableData" border style="width: 100%;">
        
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="title" label="作品名称" width="200" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="userName" label="创建者" width="200" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="time" label="创建时间" width="200"></el-table-column>
        <el-table-column prop="remark" label="编辑说明" width="200" :show-overflow-tooltip="true"></el-table-column>
        
        <el-table-column label="操作" width="500">
          <template slot-scope="scope">
            <div>
              <el-button
                @click="downloadFile(scope.row)"
                class="button-icon"
                type="text"
                size="small"
              >下载</el-button>
              <el-button type="text" size="small" class="button-icon" @click="uploadOfEdited( scope.row)">编辑后上传</el-button>
              <el-button type="text" size="small" class="button-icon" @click="browseFileEdited( scope.row)">查看本文件编辑记录</el-button>
              <el-button type="text" size="small" class="button-icon" @click="browserFile( scope.row)">预览文件</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="position:relative;width:100%;height:30px;padding-top:20px">
      <el-pagination
        style="position:absolute;right:0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY, QUERYED } from "@/services/dao.js";
import {showTimeFormat} from "@/js/common/index"
export default {    
    data(){
        return {
            title:"",
            userName:"",
            time:1, 
            remark:'', 
            showFilePath: '',
            downFilePath: '',
            pageSize:5,// 每页显示条数
            currentPage:1, // 当前页
            total:1, // 总条数 根据接口获取
        tableData: [],
        table: []
        }
    },
    created(){
      this.display(this.pageSize,0);
      // this.display();

    },
    methods: {
      
    //  flite(input) {
    //    console.log('flite')
    //   let  that = this;
    //   //  filter  数组API    includes  字符串API  
    //   //  和数组中的 includes类似
    //  that.tableData = that.table.filter(Val => {
    //     if (
      
    //       Val.name.includes(that.input) ||
    //       Val.IsAdmin.includes(that.input)
    //     ) {
    //       that.tableData.push(Val);
    //       return that.tableData;
    //       //alert(1)
    //     }
    //   });
    // },

    //初始化加载数据
    async display(limit,offest){
    //页面加载
      let data = await QUERYED(
        "POST","", ' editTogether_views_byID  (limit: '+limit+', offset: '+offest+') { id title userName time remark downFilePath showFilePath} editTogether_views_byID_aggregate {  aggregate {   count   }  }  '
      );
      console.log(data);
      // console.log("data.data.PCUsers_aggregate.aggregate.count: ",data.data.PCUsers_aggregate.aggregate.count);
      this.total=data.data.editTogether_views_byID_aggregate.aggregate.count;
      // this.tableData.splice(0, this.tableData.length);
      for (let i = 0; i < data.data.editTogether_views_byID.length; i++) {
        this.tableData.push({
            title:data.data.editTogether_views_byID[i].title,
            userName:data.data.editTogether_views_byID[i].userName,
            time:showTimeFormat(data.data.editTogether_views_byID[i].time), 
            remark:data.data.editTogether_views_byID[i].remark, 
            downFilePath:data.data.editTogether_views_byID[i].downFilePath, 
            showFilePath:data.data.editTogether_views_byID[i].showFilePath, 
            id:data.data.editTogether_views_byID[i].id, 
        });
        // return that.tableData;
        //  this.total=data.data.editTogether_views_byID_aggregate.aggregate.count;
        //  this.$message.success(that.tableData);
      } 
      ////给查询的赋值
      // this.table = that.tableData;
        
      },
        //当前页
        handleCurrentChange(val){
            console.log("handleCurrentChange",val);
            this.currentPage=val;
            // 切换页码时，要获取每页显示的条数
            this.display(this.pageSize,this.pageSize*(this.currentPage-1));
        },
        //切换每页条数
        handleSizeChange(val){
            console.log("handleSizeChange",val)
            this.pageSize=val;
            this.display(this.pageSize,0);
           // 注意：在改变每页显示的条数时，要将页码显示到第一页
            this.currentPage=1 
        },
        handleClick(row) {
            console.log(row);
        },
      // async  addCarousel(){
      //       this.$router.push("/addthemesd");
      //   },

      async uploadOfEdited(row){
          // let  data=  await  DELETE("post","",'delete_PCUsers(where: {id: {_eq: '+row.id+'}}) {  affected_rows }');
          console.log(row)
          // if(data.data.delete_PCUsers.affected_rows>0){
          // this.display() ;
          // this.$message.success("删除成功!");
          // this.display(this.pageSize,0);
          // }
          // console.log(row)
          this.$router.push({
            path: '/coordinationUploadFile',
            query:{
              id: row.id,
              title: row.title,
            }
          })
      },
      async downloadFile(row){
        console.log(row)
        // console.log("this.api.LoginURL.concat(row.downFilePath): ",this.api.LoginURL.concat(row.downFilePath))
        window.open(this.api.LoginURL.concat(row.downFilePath));
      },
      browseFileEdited(row){
        this.$router.push({
            path: '/coordinationFileRecord',
            query:{
              id: row.id,
            }
          })
      },
      browserFile(row){
        window.open(this.api.LoginURL.concat(row.showFilePath));
      }
    }
  
}
</script>

<style>
.carousel-table {
  margin-top: 20px;
  text-align: center;
}
.button-icon {
  width: 100px;
  height: 30px;
  text-align: center;
  margin-bottom: 5px;
}
.img-style{
  max-width: 100%;
  height: auto;
}
.title_file{
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: 500;
}
</style>